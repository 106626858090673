/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import SectionTitle from "../components/section-title/section-title"
import Panel from "../components/panel/panel"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import NewsItem from "../components/news-item/news-item"
import Link from "../components/link/link"
import Modal from "../components/modal/modal"
import Carousel from "../components/carousel/carousel"
import { coloredLine } from "../components/section-title/section-title"
import LoopingVideo from "../images/looping-video.mp4"

export default function About({ data }) {
  const { caseStudyImages, pressImages } = data
  const cases = [
    {
      client: "NETFLIX",
      title: "13 Reasons Why - Talk to the Reasons",
      description:
        "To help Netflix launch 13 Reasons Why Season Two, Moth+Flame created Talk To The Reasons, an immersive fan experience that gave fans the feeling that they were getting FaceTime calls from characters on the show.",
      image: getImage(
        caseStudyImages.nodes.find(img => img.name === "talk-to-the-reasons")
      ),
      video: "https://player.vimeo.com/video/274967078",
      videoId: 274967078,
    },
    {
      client: "AMC",
      title: "Walking Dead 360",
      subtitle: "Winner of Cynopsis Award for Best VR",
      description:
        "For the 100th Episode, AMC sought to promote their season premiere with an immersive experience. Working with the show, Moth+Flame extended the fan experience by creating an alternate POV of the episode climax. For fans, the 360 experience was the only place where they could see this version of the scene. Winner of Cynopsis Award for Best VR.",
      image: getImage(
        caseStudyImages.nodes.find(img => img.name === "walking-dead")
      ),
      video: "https://player.vimeo.com/video/274980488",
      videoId: 274980488,
    },
    {
      client: "Accenture",
      title: "AVEnueS",
      subtitle:
        "Winner Best AR/VR Mobile World Congress, SXSW Innovation Award Finalist",
      image: getImage(
        caseStudyImages.nodes.find(
          img => img.name === "accenture-white-on-black"
        )
      ),
      video: "https://player.vimeo.com/video/298188916",
      videoId: 298188916,
    },
    {
      title: "Fall in Love",
      description:
        "To bring human conversation into virtual reality, Moth+Flame along with Tool of NA created Fall in Love based on the New York Times’ 36 Questions to Fall in Love. Users were able to experience a real human-like conversation using the Oculus RIft VR headset. The question and answer experience was replicated with life-like conversational cadence and tempo and the result was a series of amazed users. This was made possible by the Conversive Conversation Engine, which is a building platform for conversational experiences.",
      image: getImage(
        caseStudyImages.nodes.find(img => img.name === "fall-in-love")
      ),
      video: "https://player.vimeo.com/video/274984934",
      videoId: 274984934,
    },
    {
      client: "Nick",
      title: "FaceTime with SpongeBob",
      image: getImage(
        caseStudyImages.nodes.find(img => img.name === "spongebob")
      ),
      video: "https://player.vimeo.com/video/296759851",
      videoId: 296759851,
    },
    {
      client: "IMAX",
      title: "Genius the Panda",
      subtitle: "Winner of Parent’s Choice Best AR/VR",
      description: (
        <span>
          To help IMAX launch PANDAS, an original documentary, Moth+Flame
          created Pandas AR, an augmented reality, conversation experience that
          allowed kids to talk to a panda and learn about life as a panda. The
          educational experience can be downloaded at:{" "}
          <Link to="https://AppStore.com/Yakables">AppStore.com/Yakables</Link>.
          This was made possible by the Conversive Conversation Engine, which is
          a building platform for conversational experiences.
        </span>
      ),
      image: getImage(caseStudyImages.nodes.find(img => img.name === "pandas")),
      video: "https://player.vimeo.com/video/274981100",
      videoId: 274981100,
    },
    {
      title: "Remember: Remember",
      subtitle: "Official Selection Tribeca Film Festival",
      image: getImage(
        caseStudyImages.nodes.find(img => img.name === "remember-remember")
      ),
      video: "https://player.vimeo.com/video/296760037",
      videoId: 296760037,
    },
    {
      client: "NETFLIX",
      title: "Who Killed Bryce Walker",
      description:
        "Ahead of 13 Reasons Why: Season 3, Netflix revealed the series’ fictional antagonist had been mysteriously killed. Every fan wanted to know: Who killed Bryce Walker? So Netflix created “WhoKilledBryceWalker.com” — an immersive, NLP-powered conversational experience that let fans speak directly to the town Sheriff to uncover new clues.",
      image: getImage(
        caseStudyImages.nodes.find(
          img => img.name === "who-killed-bryce-walker"
        )
      ),
      video: "https://player.vimeo.com/video/427953946",
      videoId: 427953946,
    },
  ]
  const pressItems = [
    {
      text: (
        <q>
          Pairing natural language processing and machine learning with the
          warmth of a human face.
        </q>
      ),
      to:
        "https://www.fastcompany.com/40469540/how-virtual-reality-could-help-you-fall-in-love",
      image: getImage(
        pressImages.nodes.find(img => img.name === "fast-company")
      ),
      imageStyles: {
        pt: "2rem",
      },
      alt: "Fast Company",
    },
    {
      text: <q>The pinnacle of brand innovation.</q>,
      to:
        "https://www.adweek.com/creativity/12-projects-that-are-at-the-vanguard-of-u-s-brand-innovation-today/",
      image: getImage(pressImages.nodes.find(img => img.name === "adweek")),
      alt: "AdWeek",
    },
    {
      text: (
        <q>
          A virtual reality experience that promises to remind you of what an
          emotional experience feels like.
        </q>
      ),
      to:
        "https://www.popsugar.com/news/Fall-Love-Oculus-Rift-Experience-44041991",
      image: getImage(pressImages.nodes.find(img => img.name === "popsugar")),
      imageStyles: {
        pt: "1.5rem",
      },
      alt: "PopSugar",
    },
    {
      text: (
        <q>MTV and AT&T debut ‘gaze activated’ VR experience at Comic-con</q>
      ),
      to:
        "https://adage.com/article/adreview/mtv-debuts-virtual-reality-teen-wolf-fans-comic-con/305020",
      image: getImage(pressImages.nodes.find(img => img.name === "adage")),
      imageStyles: {
        //pt: [null, null, "2.8rem"],
      },
      alt: "AdAge",
    },
    {
      text: <q>The next generation of cinematic storytelling</q>,
      to:
        "https://deadline.com/2017/09/caa-signs-first-fully-virtual-reality-director-kevin-cornish-1202167515/",
      image: getImage(pressImages.nodes.find(img => img.name === "deadline")),
      imageStyles: {
        pt: "1.5rem",
      },
      alt: "Deadline",
    },
    {
      text: (
        <q>
          Netflix came up with a genius way for fans to experience 13 Reasons
          Why
        </q>
      ),
      to:
        "https://www.refinery29.com/en-us/2018/05/199582/13-reasons-why-website-talk-to-the-reasons",
      image: getImage(pressImages.nodes.find(img => img.name === "refinery29")),
      imageStyles: {
        pt: "1.5rem",
      },
      alt: "Refinery29",
    },
  ]
  return (
    <Layout headerbg="bgGray">
      <Metadata title="About us" />
      <Panel
        sx={{
          bg: "bgGray",
          pt: [null, "6rem", "8rem"],
          pb: ["3rem", "6rem", "8rem"],
        }}
      >
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            pt: ["3rem", "unset"],
          }}
        >
          <Themed.h1
            className="scaleText"
            //sx={{ "--max-font-size": [2.6, 3.8, 5.8] }}
          >
            Moth+Flame History
          </Themed.h1>
          <Themed.p
            sx={
              {
                //variant: "text.intro",
                //fontSize: ["1.5rem", "1.6rem", "2rem"],
                //lineHeight: [1.47, 1.5, 1.6],
              }
            }
          >
            Founded in 2015, Moth+Flame first built its legacy in the
            entertainment industry creating immersive virtual reality
            experiences for companies like Netflix, MTV, Google, AMD and AT&T
            and talent like Taylor Swift while the technology was still in its
            early days of development.
          </Themed.p>
          <Themed.p>
            As the uses for VR technology expanded, so did Moth+Flame. In 2018
            Moth+Flame grew into a company that developed premier training
            solutions for public and private sector employees. Led by experts
            from immersive tech and entertainment, Moth+Flame’s VR solutions
            combine artistic prowess with engineering excellence. The company’s
            PromiseVR and READY VR training platforms, powered by AI and natural
            language processing, offer innovative, adaptive learning programs
            for hard and soft skills.
          </Themed.p>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            gridRowStart: ["1", "unset"],
            mx: ["-3rem", "unset"],
            pt: [null, "6rem", 0],
            px: [null, "1rem", "4rem"],
            perspective: [null, "500px"],
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            sx={{
              width: "100%",
              verticalAlign: "middle",
              transform: [null, "rotateY(-4deg) skewY(1deg)"],
              borderRadius: [null, "16px"],
              boxShadow: [null, "0 15px 20px 0 rgba(0, 0, 0, 0.16)"],
            }}
          >
            <source src={LoopingVideo} type="video/mp4" />
          </video>
        </div>
        {/* <div sx={{ gridColumn: "1 / -1", mx: ["-3rem", 0] }}>
          <video
            playsInline
            autoPlay
            muted
            loop
            sx={{ width: "100%", verticalAlign: "middle" }}
          >
            <source src={LoopingVideo} type="video/mp4" />
          </video>
        </div> */}
      </Panel>
      <Panel
        sx={{
          py: [null, "4rem"],
          px: [0, "6rem", "8.4rem"],
        }}
      >
        <Carousel
          childrenStyles={{
            flexBasis: ["90%", "100%"],
            mr: ["2rem", 0],
          }}
          startIndex={Math.floor(Math.random() * cases.length)}
          showDots
          loop
          autoplay
        >
          {embla => (
            <React.Fragment>
              {cases.map(
                ({ image, client, title, subtitle, description, videoId }) => (
                  <div key={title}>
                    <Modal
                      trigger={
                        <GatsbyImage
                          image={image}
                          alt={title}
                          sx={{ height: "100%", width: "100%" }}
                        />
                      }
                      triggerContainerStyles={{ height: "100%" }}
                      modalContent={
                        <div>
                          <h1
                            sx={{
                              fontSize: "1.3rem",
                              lineHeight: 1.23,
                              fontWeight: 600,
                              mt: 0,
                              mb: "2rem",
                              position: "relative",
                              pt: "1.2rem",
                              ...coloredLine({ alignTop: true }),
                            }}
                          >
                            {client}
                            {client && (
                              <span sx={{ color: "lightGray", mx: "0.75rem" }}>
                                |
                              </span>
                            )}
                            {title}
                          </h1>
                          {subtitle && (
                            <h2
                              sx={{
                                fontWeight: 300,
                                fontSize: "1.2rem",
                                mb: "2rem",
                                mt: "-1.75rem",
                              }}
                            >
                              {subtitle}
                            </h2>
                          )}
                          {description && (
                            <Themed.p sx={{ fontSize: "1.4rem" }}>
                              {description}
                            </Themed.p>
                          )}
                          <lite-vimeo videoid={videoId}></lite-vimeo>
                        </div>
                      }
                      allowOpen={() => embla && embla.clickAllowed()}
                    />
                  </div>
                )
              )}
            </React.Fragment>
          )}
        </Carousel>
      </Panel>
      <Panel
        sx={{
          bg: "bgGray",
          py: ["3rem", "6rem", "8rem"],
        }}
      >
        <SectionTitle sx={{ gridColumn: "1 / -1" }}>
          Awards + Recognition
        </SectionTitle>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            pt: ["3rem", "unset"],
            fontSize: ["1.5rem", "1.6rem", "2rem"],
            lineHeight: [1.47, 1.5, 1.6],
          }}
        >
          <Themed.h1 className="scaleText">
            A leader in immersive content creation
          </Themed.h1>
          <Themed.p sx={{ variant: "text.intro" }}>
            Groundbreaking content recognized at the higher levels.
          </Themed.p>
          <Themed.p>
            Moth+Flame has produced award-winning immersive content for brands
            ranging from Netflix, Accenture, IMAX, Estee Lauder Companies, Nick,
            Google, Oculus, AMC, Discovery, MTV, AT&T, Ram Truck, and AMD. Moth
            + Flame experiences have been recognized for excellence by
            institutions including Television Academy, Cannes Lions, SXSW, AICP
            NEXT, Future of Storytelling, Sundance, Tribeca Film Festival and
            has been permanently archived in the Museum of Modern Art.
          </Themed.p>
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
          }}
        >
          <StaticImage
            placeholder="none"
            src="../images/awards.png"
            alt="Awards"
          />
        </div>
      </Panel>
      <Panel sx={{ py: ["4rem", "6rem", "8rem"] }}>
        <SectionTitle sx={{ gridColumn: "1 / -1" }}>Press</SectionTitle>
        <Themed.h1
          className="scaleText"
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
          }}
        >
          Moth+Flame in the News
        </Themed.h1>
        <Carousel
          childrenStyles={{
            flexBasis: ["65%", "22%"],
            mr: "3rem",
          }}
          showArrows
        >
          {pressItems.map(item => (
            <NewsItem key={item.alt} {...item} />
          ))}
        </Carousel>
      </Panel>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    caseStudyImages: allFile(filter: { relativeDirectory: { eq: "cases" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
    pressImages: allFile(filter: { relativeDirectory: { eq: "press" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  }
`
